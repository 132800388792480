import { createAction } from 'typesafe-actions';

export const startNeptingPayment = createAction(
  'ENDED_NEPTING_PAYMENT_SEQUENCE',
)();

export const startNeptingInctivityWatcher = createAction(
  'START_NEPTING_INACTIVITY_WATCHER',
)();

export const neptingPaymentSequenceEnded = createAction(
  'NEPTING_PAYMENT_SEQUENCE_ENDED',
  ({ isSucess, ticket }: { isSucess: boolean; ticket?: string }) => ({
    ticket,
    isSucess,
  }),
)();

export const displayNeptingErrorMessage = createAction(
  'DISPLAY_NEPTING_ERROR_MESSAGE',
  (errorMessage: string | null) => ({
    errorMessage,
  }),
)();
