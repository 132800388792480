import { createAction } from 'typesafe-actions';
import { Customer } from 'services/innovorder';

export const waitingForBadge = createAction('WAITING_FOR_BADGE')();
export const restartInctivityWatcher = createAction(
  'RESTART_INACTIVITY_WATCHER',
)();
export const customerUpdated = createAction(
  'CUSTOMER_UPDATED',
  (customer: Customer) => customer,
)();
export const disconnectCustomer = createAction('DISCONNECT_CUSTOMER')();
