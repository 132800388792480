/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { WebBridge, WebBridgeServiceFactory } from 'services/webBridge';
import {
  BRIDGE_MESSAGE,
  MessageResponsePayload,
} from 'services/webBridge/webBridge.types';
import { v4 as uuid } from 'uuid';
import { NeptingConfiguration, NeptingTransaction } from './nepting.types';

const SERVICE_NAME = 'NeptingService';

type Message<T> = {
  resolve: (value: T) => void;
  reject: (value: unknown) => void;
};

type MessageMap<T> = {
  [key: string]: Message<T>;
};

export type NeptingSuccessfullTransactionResponse = {
  merchantTicket: null;
  merchantLabel: string;
  maskedPan: string;
  merchantTransactionId: string;
  isTestCard: boolean;
  isSignatureRequired: boolean;
  handWrittenTicket: string;
  customerTicket: string;
  initialDebitTransactionId: null;
  cardEndOfValidity: string;
  isLocalMode: boolean;
  cardToken: string;
  dateTime: string;
  authorizationNumber: string;
  authorizationCode: string;
  localTransactionCount: number;
  amount: number;
};

type NeptingResponses = NeptingSuccessfullTransactionResponse | unknown;

export default class NeptingService {
  private webBridge: WebBridge;

  public enabled = false;

  private neptingConfiguration?: NeptingConfiguration;

  private pendingMessages: MessageMap<NeptingResponses> = {};

  public transactionId?: string;

  public initialized: boolean;

  constructor() {
    this.webBridge = WebBridgeServiceFactory.getInstance();
    this.initialized = false;
  }

  /**
   * Promisify event emission using a payload enveloppe containing a messageId.
   *
   * @param {string} message - The event "name".
   * @param {*} payload - The payload of the event.
   * @returns {Promise<*>} - The promise holding the response.
   */
  emitMessage = <T>(
    message: BRIDGE_MESSAGE,
    payload?: NeptingConfiguration | NeptingTransaction,
  ): Promise<T> =>
    new Promise((resolve, reject) => {
      const messageId: string = uuid();

      if (this.webBridge && this.webBridge.ready) {
        this.pendingMessages[messageId] = { resolve, reject } as Message<
          NeptingResponses
        >;
        this.webBridge.emitMessage(
          message,
          payload ? { ...payload, messageId } : { messageId },
        );
      } else {
        reject(Error('NEPTING_WEB_BRIDGE_UNAVAILABLE'));
      }
    });

  /**
   * Init webbridge listener to Nepting message responses.
   */
  initWebBridgeListener = () => {
    // eslint-disable-next-line consistent-return
    const listener = (response: MessageResponsePayload) => {
      if (!response.messageId) {
        return;
      }
      if (this.pendingMessages[response.messageId]) {
        const { resolve, reject } = this.pendingMessages[response.messageId];
        delete this.pendingMessages[response.messageId];

        if (response.error) {
          // eslint-disable-next-line consistent-return
          return reject(response.payload);
        }

        // eslint-disable-next-line consistent-return
        return resolve(response.payload);
      }

      // TODO better error handling
      console.error(SERVICE_NAME, 'No pending messages for', response);
    };

    if (this.webBridge.ready) {
      this.webBridge.on(
        BRIDGE_MESSAGE.NEPTING_PINPAD_RESPONSE,
        'NEPTING_SERVICE',
        listener,
      );
    }
  };

  // Init

  /**
   *
   * @param restaurantId
   * @param channelId
   * @returns {Promise<init>}
   */
  // eslint-disable-next-line consistent-return
  async init(merchantId: string) {
    this.initWebBridgeListener();

    return this.initConnection(merchantId).catch(err => {
      console.error(SERVICE_NAME, 'Could not init connection', err);
      throw Error('Could not init connection');
    });
  }

  // Bridge

  /**
   *
   * @returns {Promise<unknown>}
   */
  async initConnection(merchantId: string) {
    this.initialized = false;

    const login = await this.emitMessage(
      BRIDGE_MESSAGE.NEPTING_PINPAD_INITIALIZE,
      { merchantId },
    );

    this.initialized = true;
    this.neptingConfiguration = {
      merchantId,
    };

    return login;
  }

  /**
   *
   * @returns {Promise<unknown>}
   */
  getTerminalInfo() {
    return this.emitMessage(
      BRIDGE_MESSAGE.NEPTING_PINPAD_GET_TERMINAL_INFORMATION,
    );
  }

  /**
   *
   * @param cents
   * @param currency
   * @param paymentMethod
   * @returns {Promise<unknown>}
   */
  startTransaction = ({
    cents,
  }: {
    cents: number;
  }): Promise<NeptingSuccessfullTransactionResponse> => {
    if (this.transactionId) {
      throw Error('NEPTING_PENDING_TRANSACTION');
    }

    this.transactionId = uuid();

    return this.emitMessage<NeptingSuccessfullTransactionResponse>(
      BRIDGE_MESSAGE.NEPTING_PINPAD_START_TRANSACTION,
      {
        cents,
        currency: 'EUR',
        paymentMethod: 'DEBIT',
        merchantTransactionId: this.transactionId,
      },
    ).finally(() => {
      this.transactionId = undefined;
    });
  };

  //   /**
  //    *
  //    * @returns {Promise<unknown>}
  //    */
  //   async function abortTransaction() {
  //     if (!this.transactionId) {
  //       console.error(SERVICE_NAME, 'no pending transaction', this.transactionId);
  //       throw Error('NEPTING_NO_PENDING_TRANSACTION');
  //     }

  //     return emitMessage(BRIDGE_MESSAGE.NEPTING_PINPAD_ABORT_TRANSACTION).finally(
  //       () => {
  //         this.transactionId = null;
  //       },
  //     );
  //   }

  // Getters/setters

  /**
   *
   * @returns {boolean}
   */
  isEnabled() {
    return !!this.enabled;
  }

  /**
   *
   * @returns {boolean}
   */
  isInitialized() {
    return !!this.initialized;
  }

  /** Reload nepting if enabled but not initialized
   *
   * @returns {Promise<unknown>}
   */
  //   async function thisReload() {
  //     if (!isEnabled()) {

  //         `[Angularcore][NepingService][thisReload] Nepting is disabled`,
  //       );
  //       return;
  //     }

  //     try {
  //       if (!isInitialized()) {

  //           `[Angularcore][NepingService][thisReload] initConnection`,
  //         );
  //         await initConnection();
  //       }
  //       const terminalInfo = await getTerminalInfo();

  //         `[Angularcore][NepingService][thisReload] terminalInfo`,
  //         terminalInfo,
  //       );
  //     } catch (e) {

  //         `[Angularcore][NepingService][thisReload] Error`,
  //         e,
  //       );
  //     }
  //   }
}

export const NeptingServiceFactory = (function() {
  let instance: NeptingService;
  return {
    getInstance() {
      if (instance == null) {
        instance = new NeptingService();
      }
      return instance;
    },
  };
})();
