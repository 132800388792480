import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import IconPage from 'components/IconPage';
import nfc from './nfc.svg';

interface Props {
  waitingForBadge: () => unknown;
}

const Badge: React.FunctionComponent<Props> = ({ waitingForBadge }) => {
  useEffect(() => {
    waitingForBadge();
  }, [waitingForBadge]);

  return (
    <IconPage icon={nfc} iconAlt="NFC">
      <FormattedMessage id="badge.mainCTA" />
    </IconPage>
  );
};

export default Badge;
