import { WebBridge, WebBridgeServiceFactory } from 'services/webBridge';
import { BRIDGE_MESSAGE } from 'services/webBridge/webBridge.types';
import { InfoPayload } from './index.types';

export class SimpleLogService {
  private webBridge: WebBridge;

  constructor() {
    this.webBridge = WebBridgeServiceFactory.getInstance();
  }

  info(message: string, payload?: InfoPayload): void {
    this.webBridge.emitMessage(BRIDGE_MESSAGE.LOG, { message, payload });
  }

  space(): void {
    this.webBridge.emitMessage(BRIDGE_MESSAGE.LOG, 'space');
  }
}

export const SimpleLogServiceFactory = (function(): {
  getInstance: () => SimpleLogService;
} {
  let instance: SimpleLogService;
  return {
    getInstance(): SimpleLogService {
      if (instance == null) {
        instance = new SimpleLogService();
      }
      return instance;
    },
  };
})();
