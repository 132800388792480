import React from 'react';
import styled from 'styled';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import SettingsForm from 'components/SettingsForm';
import { TerminalInfo } from 'services/Nepting/nepting.types';
import { PrinterInfos } from 'services/Printer/printer.types';
import exit from './exit.svg';
import off from './off.svg';
import printer from './printer.svg';

const BTN_FONT_SIZE = '14px';
const PRODUCT_FONT_SIZE = '14px';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  border-bottom: 1px solid ${({ theme }): string => theme.color.inputs};
  margin: 35px 50px;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
`;
const Logo = styled.img`
  max-width: 190px;
  margin-right: 20px;
`;
const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  flex-grow: 1;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  flex-grow: 0;
`;

const Button = styled(Link)`
  border-radius: 50px;
  position: relative;
  height: 40px;
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${BTN_FONT_SIZE};
  margin-bottom: 10px;
  color: ${({ theme }): string => theme.color.textOnPrimary};
  background-color: ${({ theme }): string => theme.color.primary};
  text-decoration: none;
  text-transform: uppercase;
`;

const Logout = styled.div`
  position: relative;
  border-radius: 50px;
  height: 40px;
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: ${BTN_FONT_SIZE};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }): string => theme.color.inputs};
  color: ${({ theme }): string => theme.color.textLight};
  text-transform: uppercase;
`;

const ProductTitle = styled.p`
  color: ${({ theme }): string => theme.color.textLight};
  font-size: ${PRODUCT_FONT_SIZE};
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const BtnIcon = styled.img`
  position: absolute;
  left: 30px;
  height: 15px;
`;

const PrintIcon = styled.img`
  height: 40px;
`;

const InfoContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }): string => theme.color.textLight};
  border: 1px solid ${({ theme }): string => theme.color.inputs};
  padding: 25px;
  width: 95%;
`;

const InfoTextContainer = styled.div`
  margin-top: 30px;
`;

const Text = styled.p`
  font-family: 'Avenir', sans-serif;
`;

interface Props {
  logout: () => unknown;
  printTestTicket: () => unknown;
  terminalInfo: TerminalInfo | undefined;
  printerInfo: PrinterInfos | null | undefined;
}

const Config: React.FunctionComponent<Props> = ({
  logout,
  printerInfo,
  terminalInfo,
  printTestTicket,
}) => {
  const model = terminalInfo?.model;
  const serialNumber = terminalInfo?.serialNumber;

  return (
    <Container>
      <Header>
        <Logo src="https://static-dev.innovorder.fr/io/images/logo4.png" />
        <ProductTitle>
          <FormattedMessage id="login.product" />
        </ProductTitle>
      </Header>
      <Body>
        <Center>
          <SettingsForm />
          {model && serialNumber && (
            <InfoContainer>
              <div>
                <ProductTitle>
                  <FormattedMessage id="config.neptingTerminal" />
                </ProductTitle>
                <InfoTextContainer>
                  <Text>
                    <b>
                      <FormattedMessage id="config.model" />
                    </b>
                    {model}
                  </Text>
                  <Text>
                    <b>
                      <FormattedMessage id="config.serialNumber" />
                    </b>
                    {serialNumber}
                  </Text>
                </InfoTextContainer>
              </div>
            </InfoContainer>
          )}
          {printerInfo && (
            <InfoContainer onClick={printTestTicket}>
              <div>
                <ProductTitle>
                  <FormattedMessage id="config.printers" />
                </ProductTitle>
                <InfoTextContainer>
                  <Text>
                    <b>
                      <FormattedMessage id="config.printerName" />
                    </b>
                    {printerInfo.name}
                  </Text>
                  <Text>
                    <b>
                      <FormattedMessage id="config.target" />
                    </b>
                    {printerInfo.target}
                  </Text>
                </InfoTextContainer>
              </div>
              <PrintIcon src={printer} />
            </InfoContainer>
          )}
        </Center>
        <Footer>
          <Logout onClick={logout}>
            <BtnIcon src={exit} />
            <FormattedMessage id="config.logout" />
          </Logout>
          <Button to="/">
            <BtnIcon src={off} />
            <FormattedMessage id="config.start" />
          </Button>
        </Footer>
      </Body>
    </Container>
  );
};

export default Config;
