import { createReducer, ActionType } from 'typesafe-actions';
import { Customer } from 'services/innovorder';
import * as customerActions from './customer.actions';

export type CustomerActions = ActionType<typeof customerActions>;

const defaultState = null;

export type CustomerState = null | Customer;

const customerReducer = createReducer<CustomerState, CustomerActions>(
  defaultState,
)
  .handleAction(
    customerActions.customerUpdated,
    (state, { payload: customer }) => customer,
  )
  .handleAction(customerActions.disconnectCustomer, () => null);

export default customerReducer;
