import React from 'react';
import { FormattedNumber } from 'react-intl';
import styled, { theme as importedTheme } from 'styled';
import { motion } from 'framer-motion';

const ITEM_FONT_SIZE = '80px';
export const ITEM_SIZE = '220px';

const variants = {
  selected: {
    color: importedTheme.color.textOnPrimary,
    backgroundColor: importedTheme.color.primary,
    scale: 0.95,
  },
  unselected: {
    color: importedTheme.color.text,
    backgroundColor: importedTheme.color.backgroundhHighlight,
    scale: 1,
  },
  disabled: {
    color: importedTheme.color.disabledText,
    backgroundColor: importedTheme.color.backgroundDisabled,
    scale: 0.95,
  },
};

const ItemContainer = styled(motion.li)`
  list-style: none;
  height: ${ITEM_SIZE};
  width: 100%;
  display: flex;
  color: ${({ theme }): string => theme.color.text};
  justify-content: center;
  align-items: center;
  font-size: ${ITEM_FONT_SIZE};
  font-weight: 900;
  border: 1px dashed #454545;
  text-align: center;
  border-radius: ${({ theme }): string => theme.radius.main};
  background-color: ${({ theme }): string => theme.color.backgroundhHighlight};

  &:last-of-type {
    grid-column: 1 / 3;
  }
`;

interface Props {
  amount: number;
  selectAmount: () => unknown;
  isSelected: boolean;
  isPostPayment: boolean;
}

const Item: React.FunctionComponent<Props> = ({
  amount,
  selectAmount,
  isSelected,
  isPostPayment,
}) => {
  const disabled = ((): string => {
    if (isPostPayment) {
      return 'disabled';
    }
    return isSelected ? 'selected' : 'unselected';
  })();
  return (
    <ItemContainer
      onClick={isPostPayment ? (): unknown | null => null : selectAmount}
      variants={variants}
      animate={disabled}
      transition={{
        scale: {
          type: 'spring',
          stiffness: 500,
        },
      }}
    >
      <FormattedNumber
        style="currency"
        currency="EUR"
        value={amount / 100}
        minimumFractionDigits={0}
      />
    </ItemContainer>
  );
};

export default Item;
