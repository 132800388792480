import React from 'react';
import styled from 'styled';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';

const customStyles = {
  /* stylelint-disable */
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    top: 'unset',
    left: 'unset',
    right: 'unset',
    bottom: 'unset',
    width: '480px',
    padding: '40px',
  },
  /* stylelint-enable */
};

const Container = styled.div`
  font-size: ${({ theme }): string => theme.font.size.text};
  text-align: center;
`;

interface Props {
  closeModal: () => unknown;
  isOpen: boolean;
  contentId: string;
  ctaId: string;
  component?: React.FunctionComponent<{ onClose: () => unknown }>;
}

const ModalContainer: React.FunctionComponent<Props> = ({
  closeModal,
  isOpen,
  contentId,
  ctaId,
  component,
}) => {
  let popupContent: JSX.Element;

  if (component !== undefined) {
    const Test = component;
    popupContent = <Test onClose={closeModal} />;
  } else {
    popupContent = (
      <Container>
        <FormattedMessage id={contentId} />
        <Button onClick={closeModal}>
          <FormattedMessage id={ctaId} />
        </Button>
      </Container>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={closeModal}
      ariaHideApp={false}
    >
      {isOpen && popupContent}
    </Modal>
  );
};

export default ModalContainer;
